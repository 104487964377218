var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module_wrap"},[_c('div',{staticClass:"times_top"},[_c('ul',[_c('li',[(_vm.staticsDataFlag)?_c('countTo',{staticClass:"totla_num",attrs:{"startVal":_vm.numDataStart,"endVal":_vm.totalData.deviceNum,"duration":500}}):_c('span',{staticClass:"totla_num"},[_vm._v("0")]),_vm._m(0),_vm._m(1)],1),_c('li',[(_vm.staticsDataFlag)?_c('countTo',{staticClass:"totla_num",attrs:{"startVal":_vm.numDataStart,"endVal":_vm.totalData.totalMileage,"duration":500}}):_c('span',{staticClass:"totla_num"},[_vm._v("0")]),_vm._m(2),_vm._m(3)],1),_c('li',[(_vm.staticsDataFlag)?_c('countTo',{staticClass:"totla_num",attrs:{"startVal":_vm.numDataStart,"endVal":_vm.totalData.vehicleNum,"duration":500}}):_c('span',{staticClass:"totla_num"},[_vm._v("0")]),_vm._m(4),_vm._m(5)],1),_c('li',[(_vm.staticsDataFlag)?_c('countTo',{staticClass:"totla_num",attrs:{"startVal":_vm.numDataStart,"endVal":_vm.totalData.totalDuration,"duration":500}}):_c('span',{staticClass:"totla_num"},[_vm._v("0")]),_vm._m(6),_vm._m(7)],1),_c('li',[(_vm.staticsDataFlag)?_c('countTo',{staticClass:"totla_num",attrs:{"startVal":_vm.numDataStart,"endVal":_vm.totalData.tripsNum,"duration":500}}):_c('span',{staticClass:"totla_num"},[_vm._v("0")]),_vm._m(8),_vm._m(9)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"active"},[_vm._v("设备总数")]),_c('span',[_vm._v("（台）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total_icon"},[_c('img',{attrs:{"src":require("../../public/images/msi/total_icon1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"active"},[_vm._v("总行驶里程")]),_c('span',[_vm._v("（千米）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total_icon"},[_c('img',{attrs:{"src":require("../../public/images/msi/total_icon2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"active"},[_vm._v("车辆总数")]),_c('span',[_vm._v("（辆）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total_icon"},[_c('img',{attrs:{"src":require("../../public/images/msi/total_icon3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"active"},[_vm._v("总行驶时长")]),_c('span',[_vm._v("（小时）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total_icon"},[_c('img',{attrs:{"src":require("../../public/images/msi/total_icon4.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"active"},[_vm._v("总行程数量")]),_c('span',[_vm._v("（条）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"total_icon"},[_c('img',{attrs:{"src":require("../../public/images/msi/total_icon5.png")}})])
}]

export { render, staticRenderFns }