<template>
  <div class="module_wrap">
  	<div class="score_text">
  		<b>平均出行天数TOP3城市</b>
  		<span>（天）</span>
  	</div>
	<div class="line"></div>
	<ul class="times_top">
		<li v-for="(item,index) in topDays" :key="index">
			<div class="top_icon"><img :src="require('../../public/images/msi/top'+(index+1)+'.png')" ></div>
			<div class="city">
				<p>{{item.cityName}}</p>
				<span>{{item.avgDays}}天</span>
			</div>
		</li>
	</ul>
  </div>
</template>

<script>
export default {
  name: 'DaysRanking',
  props: {
  },
  data(){
	  return{
		topDays:[],
		newNumber:0,
	  }
  },
  mounted() {
	  this.role=localStorage.getItem("role");
	  this.getData();
  },
  methods:{
	  getData(){
		 this.instance.get('/homepage/lastMonthAvgDrivingDaysTopCity',{
			params:{role:this.role}
		 }).then(res=>{
			 if(res.data.code==1){
				 var topDays=res.data.data||[];
				 for(var i=0;i<3;i++){
					 if(topDays[i]){
						 topDays[i].avgDays>0&&(topDays[i].avgDays=topDays[i].avgDays.toFixed(1));
						 topDays[i].cityName=topDays[i].cityName;
					 }else{
						 var obj={
							 avgDays:0,
							 cityName:'无数据'
						 }
						 topDays.push(obj)
					 }
				 }
				 this.topDays=topDays;
				 // console.log(this.topDays);
			 }
		 }); 
	  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.module_wrap{
		position: absolute;
		bottom:0.32rem;
		right:0.3rem;
		z-index: 50;
		width:5rem ;
		height: 1.46rem;
		background: url(../../public/images/msi/box_7.png) no-repeat;
		background-size: 100% 100%;
		padding: 0.2rem 0;
	}
	.score_text{
		font-size:0.18rem;
		padding-left:0.2rem;
	}
	.score_text span{
		font-size:0.14rem;
	}
	.line{
		width:1.86rem;
		height:1px;
		background:url(../../public/images/msi/line.png) no-repeat;
		background-size: 100% 100%;
		margin: 0.14rem 0 0.1rem 0.2rem;
	}
	.times_top{
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding:0 0.05rem;
	}
	.times_top li{
		width: 1.51rem;
		font-size: 0.14rem;
		font-weight: 500;
		color: #30FDFF;
		padding-top:0.15rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.top_icon{
		width: 0.42rem;
		margin-right:0.05rem;
	}
	.city p{
		font-size: 13px;
		/* width: 0.9rem;
		height: 0.16rem;
		overflow: hidden; */
	}
	.city span{
		/* font-size: 0.14rem; */
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #BAC3C4;
		display:inline-block;
		margin-top: 0.04rem;
	}
</style>
