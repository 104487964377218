<template>
    <div class="content" :class="{ device_pixel_ratio: devicePixelRatio >= 1.5 }">
        <div id="container" tabindex="0"></div>
        <!-- 轮播块 -->
        <div class="slide_box">
            <p class="slide_title">车辆实时行程</p>
            <div class="hint_text" v-show="realTimeData.length == 0">
                <p>暂无数据</p>
                <p class="active">暂无数据</p>
            </div>
            <el-carousel v-show="realTimeData.length > 0" class="slide_carousel" direction="vertical" indicator-position="none">
                <el-carousel-item v-for="item in realTimeData" :key="item.id">
                    <ul class="route_list">
                        <li @click="linkVehicleMap(item[0])">
                            <span>{{ item[0].platNo }}</span>
                            <b>{{ item[0].startAndEnd }}</b>
                        </li>
                        <li class="active" @click="linkVehicleMap(item[1])">
                            <span>{{ item[1].platNo }}</span>
                            <b>{{ item[1].startAndEnd }}</b>
                        </li>
                    </ul>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>
<script>
var _this = ''
// import axios from 'axios'
import * as echarts from 'echarts'
import { cityMap, geoCoordMap, cityToPinyin } from '@/assets/js/mapJson.js'
export default {
    name: 'ChinaMap',
    props: {
        msg: String,
        devicePixelRatio: {
            type: Number,
            default: 1
        },
        provinceDist: {
            type: Array,
            default: () => {
                return []
            }
        },
        cityDist: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            map: '',
            loca: '',
            checkedPoint: [], //点击选中的poi
            mapData: {},
            myChartMap: '',
            chart: null,
            provinceCount: [],
            realTimeData: [], //实时行程
            cityCount: [],
            nowVehicleData: {},
            queryForm: {
                condition: {
                    cityName: '中国'
                },
                pageNo: 1,
                pageSize: 20
            }
        }
    },
    mounted() {
        _this = this
        this.role = localStorage.getItem('role')
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.getLatestTrip()
        window.addEventListener('resize', this.listenResize)

        this.initChartsMap()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.listenResize)
    },
    watch: {
        cityDist(value) {
            this.getData()
        }
    },
    methods: {
        listenResize() {
            if (this.myChartMap) {
                setTimeout(() => {
                    this.myChartMap.resize()
                    this.getLatestTrip()
                }, 200)
            }
        },
        //跳转
        linkVehicleMap(row) {
            if (this.userInfo.batchId || this.role == 0) {
                return false
            }
            let routeUrl = this.$router.resolve({
                path: '/vehicleMap',
                query: {
                    deviceNumber: row.deviceNumber,
                    startTime: row.tripStartTime,
                    endTime: row.tripEndTime
                }
            })
            window.open(routeUrl.href, '_blank')
        },
        //全国最近的30条行程
        getLatestTrip() {
            this.instance.post('/ubi/api/trip/page', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.initRouteData(data.data.list)
                }
            })
        },
        //所选省份最近的30条行程
        getLatestTripByProvince(province) {
            this.queryForm.condition.cityName = province
            this.instance.post('/ubi/api/trip/page', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    this.initRouteData(data.data.list)
                }
            })
        },
        //整理车辆实时行程
        initRouteData(response) {
            var len = response.length
            if (len > 0) {
                for (var i = 0; i < len; i++) {
                    var startTime = response[i].startTime
                    var endTime = response[i].endTime
                    startTime = startTime.split(' ')[1].slice(0, -3)
                    response[i].startTime = startTime
                    endTime = endTime.split(' ')[1].slice(0, -3)
                    response[i].endTime = endTime
                    response[i].startAndEnd = startTime + '-' + endTime
                }
                this.realTimeData = this.split_array(response, 2)
            } else {
                this.realTimeData = []
            }
        },
        //切分数组
        split_array(arr, len) {
            var a_len = arr.length
            var result = []
            for (var i = 0; i < a_len; i += len) {
                result.push(arr.slice(i, i + len))
            }
            return result
        },
        //获取地图数据
        getData() {
            this.mapData.cityDatas = this.cityDist
            this.mapData.provinceDatas = this.provinceDist

            var cityCount = [],
                provinceCount = []

            if (this.mapData.cityDatas && this.mapData.cityDatas.length > 0) {
                this.mapData.cityDatas.forEach((val, key) => {
                    var obj = {
                        name: val.area,
                        parent: val.parent,
                        value: val.vehN,
                        level: 1,
                        totalDuration: val.time ? val.time.toFixed(2) : val.time,
                        totalMileage: val.mile ? val.mile.toFixed(2) : val.mile,
                        tripsNum: val.tripN
                    }
                    cityCount.push(obj)
                })
                this.cityCount = cityCount
            }

            if (this.mapData.provinceDatas && this.mapData.provinceDatas.length > 0) {
                this.mapData.provinceDatas.forEach((val, key) => {
                    var obj = {
                        name: val.area,
                        parent: val.parent,
                        value: val.vehN,
                        level: 1,
                        totalDuration: val.time,
                        totalMileage: val.mile,
                        tripsNum: val.tripN
                    }
                    provinceCount.push(obj)
                })
                this.provinceCount = provinceCount
            }

            this.initChartsMap()
        },
        //创建地图
        createMap(id, opt) {
            var _this = this

            echarts.extendsMap = function (id, opt) {
                // 实例
                _this.chart = echarts.init(document.getElementById(id), null, { renderer: 'svg' })
                var provinceData = [] //省份渠道数据
                var curGeoJson = {} //城市数据
                var levelColorMap = {
                    1: 'rgba(241, 109, 115, .8)',
                    2: 'rgba(255, 235, 59, .7)',
                    3: 'rgba(147, 235, 248, 1)'
                }

                var defaultOpt = {
                    mapName: 'china', // 地图展示
                    goDown: false, // 是否下钻
                    bgColor: '#404a59', // 画布背景色
                    activeArea: [], // 区域高亮,同echarts配置项
                    data: [], //城市名称：设备数量
                    // 下钻回调(点击的地图名、实例对象option、实例对象)
                    callback: function (name, option, instance) {}
                }
                if (opt) {
                    opt = echarts.util.extend(defaultOpt, opt)
                }

                // 层级索引
                var name = [opt.mapName]
                var idx = 0

                //中国的位置
                var pos = {
                    leftPlus: 115,
                    leftCur: 150,
                    left: 198,
                    top: 240
                }

                var line = [
                    [0, 0],
                    [8, 11],
                    [0, 22]
                ]
                // style
                var style = {
                    font: '18px "Microsoft YaHei", sans-serif',
                    textColor: '#30FBFE',
                    lineColor: '#30FBFE'
                    // lineColor: 'rgba(147, 235, 248, .8)'
                }

                var handleEvents = {
                    /**
                     * i 实例对象
                     * o option
                     * n 地图名
                     **/
                    resetOption: function (i, o, n) {
                        if (n === 'china') {
                            // myVue.searchVisible=false;
                            // debugger
                            option.series[1].data = handleEvents.initSeriesData(_this.cityCount).slice(1, 5)
                            option.series[2].data = handleEvents.initSeriesData(_this.cityCount).slice(0, 1)
                        }
                        var breadcrumb = this.createBreadcrumb(n)
                        var j = name.indexOf(n)
                        var l = o.graphic.length
                        // debugger
                        if (j < 0) {
                            o.graphic.push(breadcrumb)
                            o.graphic[0].children[0].shape.x2 = 145
                            o.graphic[0].children[1].shape.x2 = 145
                            if (o.graphic.length > 2) {
                                var cityData = []
                                var cityJson
                                for (var x = 0; x < opt.data.length; x++) {
                                    // 后台返回行政区域带'省',前端不带
                                    let tmpIndex = opt.data[x].parent.indexOf('省')
                                    let tmpName = tmpIndex != -1 ? opt.data[x].parent.slice(0, tmpIndex) : null
                                    opt.data[x].parent = tmpName ? tmpName : opt.data[x].parent

                                    if (n === opt.data[x].parent) {
                                        var optData = [opt.data[x]]
                                        // $([opt.data[x]]).each(function(index,data){
                                        cityJson = {
                                            name: optData[0].name,
                                            value: optData[0].value,
                                            tripsNum: optData[0].tripsNum,
                                            totalMileage: optData[0].totalMileage,
                                            totalDuration: optData[0].totalDuration,
                                            parent: n
                                        }
                                        cityData.push(cityJson)

                                        // })
                                    }
                                }
                                if (cityData != null) {
                                    o.series[0].data = handleEvents.initSeriesData(cityData)
                                } else {
                                    o.series[0].data = []
                                }
                            }

                            name.push(n)
                            idx++
                        } else {
                            o.graphic.splice(j + 2, l)
                            if (o.graphic.length <= 2) {
                                o.graphic[0].children[0].shape.x2 = 60
                                o.graphic[0].children[1].shape.x2 = 60
                                o.series[0].data = handleEvents.initSeriesData(opt.data)
                            }
                            name.splice(j + 1, l)
                            idx = j
                            pos.leftCur -= pos.leftPlus * (l - j - 1)
                        }
                        o.geo.map = n
                        o.geo.zoom = 0.4
                        i.clear()
                        i.setOption(o)
                        this.zoomAnimation(n)
                        opt.callback(n, o, i)
                    },
                    /**
                     * name 地图名
                     **/
                    createBreadcrumb: function (name) {
                        var breadcrumb = {
                            type: 'group',
                            id: name,
                            // show:false,
                            left: '49%',
                            z: 999,
                            top: pos.top + 3,
                            children: [
                                {
                                    type: 'polyline',
                                    left: -90,
                                    top: -5,
                                    z: 999,
                                    shape: {
                                        points: line
                                    },
                                    style: {
                                        stroke: '#30FBFE',
                                        key: name
                                        // lineWidth: 2,
                                    },
                                    onclick: function () {
                                        var name = this.style.key
                                        handleEvents.resetOption(chart, option, name)
                                    }
                                },
                                {
                                    type: 'text',
                                    left: -68,
                                    z: 999,
                                    top: 'middle',
                                    style: {
                                        text: name,
                                        textAlign: 'center',
                                        fill: style.textColor,
                                        font: style.font
                                    },
                                    onclick: function () {
                                        var name = this.style.text
                                        handleEvents.resetOption(chart, option, name)
                                    }
                                },
                                {
                                    type: 'text',
                                    left: -68,
                                    z: 999,
                                    top: 10,
                                    style: {
                                        name: name,
                                        text: cityToPinyin[name] ? cityToPinyin[name].toUpperCase() : '',
                                        textAlign: 'center',
                                        fill: style.textColor,
                                        font: '12px "Microsoft YaHei", sans-serif'
                                    },
                                    onclick: function () {
                                        var name = this.style.name
                                        handleEvents.resetOption(chart, option, name)
                                    }
                                }
                            ]
                        }
                        pos.leftCur += pos.leftPlus
                        // return breadcrumb;
                    },
                    // 设置effectscatter
                    initSeriesData: function (data) {
                        var temp = []
                        for (var i = 0; i < data.length; i++) {
                            var geoCoord = geoCoordMap[data[i].name]
                            if (geoCoord) {
                                temp.push({
                                    name: data[i].name,
                                    value: geoCoord.concat(data[i].value),
                                    num: data[i].value,
                                    totalMileage: data[i].totalMileage,
                                    totalDuration: data[i].totalDuration,
                                    tripsNum: data[i].tripsNum,
                                    region: data[i].region
                                })
                            }
                        }
                        return temp
                    },
                    zoomAnimation: function (n) {
                        var count = null
                        var _zoom = n == '中国' ? 1 : 0.8
                        var zoom = function (per) {
                            if (!count) count = per
                            count = count + per
                            chart.setOption({
                                geo: {
                                    zoom: count
                                }
                            })

                            if (count < _zoom)
                                window.requestAnimationFrame(function () {
                                    zoom(0.2)
                                })
                        }
                        window.requestAnimationFrame(function () {
                            zoom(0.2)
                        })
                    }
                }
                var option = {
                    backgroundColor: opt.bgColor,
                    //提示框组件
                    tooltip: {
                        show: true
                    },
                    //原生图形元素组件
                    graphic: [
                        {
                            type: 'group',
                            left: '45%',
                            top: pos.top - 4,
                            children: [
                                {
                                    type: 'line',
                                    left: 0,
                                    z: 999,
                                    top: -20,
                                    shape: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 60,
                                        y2: 0
                                    },
                                    style: {
                                        // stroke: style.lineColor
                                        stroke: 'transparent'
                                    }
                                },
                                {
                                    type: 'line',
                                    z: 999,
                                    left: 0,
                                    top: 20,
                                    shape: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 60,
                                        y2: 0
                                    },
                                    style: {
                                        stroke: 'transparent'
                                        // stroke: style.lineColor
                                    }
                                }
                            ]
                        },
                        {
                            id: name[idx],
                            type: 'group',
                            left: '46%',
                            z: 999,
                            top: pos.top,
                            children: [
                                {
                                    type: 'polyline',
                                    left: 90,
                                    top: -12,
                                    z: 999,
                                    shape: {
                                        points: line
                                    },
                                    style: {
                                        stroke: 'transparent',
                                        key: name[0]
                                    },
                                    onclick: function () {
                                        var name = this.style.key
                                        handleEvents.resetOption(chart, option, name)
                                    }
                                }
                            ]
                        }
                    ],
                    //地理坐标系组件
                    geo: {
                        map: opt.mapName,
                        roam: true,
                        zoom: 1, //当前视角的缩放比例
                        top: '10%', //地图的位置
                        tooltip: {
                            show: true,
                            trigger: 'item',
                            alwaysShowContent: false,
                            backgroundColor: 'rgba(8, 15, 61, 0.8)',
                            borderColor: '#00E0FF',
                            borderWidth: 1,
                            textStyle: {
                                color: '#fff'
                            },
                            hideDelay: 100,
                            enterable: true,
                            formatter: function (params, ticket, callback) {
                                if (params.componentType == 'geo') {
                                    var provinceCount = _this.provinceCount
                                    var provinceKey = provinceCount.findIndex((value) => {
                                        return value.name == params.name
                                    })
                                    if (provinceKey != -1) {
                                        return (
                                            '城市：' +
                                            provinceCount[provinceKey].name +
                                            '<br/>' +
                                            '车辆数：' +
                                            provinceCount[provinceKey].value +
                                            '辆' +
                                            '<br/>' +
                                            '行程数：' +
                                            provinceCount[provinceKey].tripsNum +
                                            '条' +
                                            '<br/>' +
                                            '行驶里程：' +
                                            provinceCount[provinceKey].totalMileage +
                                            '千米' +
                                            '<br/>' +
                                            '行驶时长：' +
                                            provinceCount[provinceKey].totalDuration +
                                            '小时'
                                        )
                                    } else {
                                        return params.name
                                    }
                                } else {
                                    return '城市：' + params.data.name + '<br/>' + '车辆数：' + params.data.num + '辆' + '<br/>' + '行程数：' + params.data.tripsNum + '条' + '<br/>' + '行驶里程：' + params.data.totalMileage + '千米' + '<br/>' + '行驶时长：' + params.data.totalDuration + '小时'
                                }
                            }
                        },
                        label: {
                            show: true,
                            color: '#fff'
                        },
                        itemStyle: {
                            borderColor: '#2ff7fb',
                            borderWidth: 1,
                            opacity: 1,
                            areaColor: {
                                type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'RGBA(0, 36, 109, 0.5)' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: 'RGBA(0, 36, 109, 0.7)' // 100% 处的颜色
                                    }
                                ],
                                globalCoord: false // 缺省为 false
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                areaColor: 'rgba(0, 69, 109, 0.8)',
                                borderWidth: 0
                            },
                            label: {
                                color: '#fff'
                            }
                        }
                    },
                    series: [
                        {
                            type: 'effectScatter',
                            coordinateSystem: 'geo',
                            showEffectOn: 'emphasis', //涟漪触发方式 render=始终显示 emphasis=hover时显示
                            //symbol:'image://http://xxx.xxx.xxx/a/b.png', //标记的图形
                            //symbolKeepAspect:true,//是否在缩放时保持该图形的长宽比
                            rippleEffect: {
                                //涟漪特效
                                period: 5,
                                scale: 2.5,
                                brushType: 'stroke'
                            },
                            // hoverAnimation: true,
                            itemStyle: {
                                color: '#06dff9',
                                shadowBlur: 10,
                                shadowColor: '#333'
                            },
                            // symbol:"image://data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7",
                            symbolSize(value, params) {
                                var _val = value[2]
                                var maxVal = opt.data[0].value
                                var _size = 10
                                if (_val < maxVal * 0.2) {
                                    _size = 8
                                } else if (_val < maxVal * 0.4) {
                                    _size = 10
                                } else if (_val < maxVal * 0.6) {
                                    _size = 12
                                } else if (_val < maxVal * 0.8) {
                                    _size = 14
                                } else {
                                    _size = 16
                                }
                                return _size
                            }, //图标大小
                            zlevel: 1,
                            // symbolSize: function (val) {   #e44a15
                            //     val=val[2] / 2>15?val[2] / 2:15;
                            //     val=val<=25?val:25;
                            //     return val;
                            // },          //图标大小
                            data: handleEvents.initSeriesData(opt.data).slice(5) //数据
                        },
                        {
                            type: 'effectScatter',
                            coordinateSystem: 'geo',
                            showEffectOn: 'emphasis', //涟漪触发方式 render=始终显示 emphasis=hover时显示
                            rippleEffect: {
                                //涟漪特效
                                period: 4,
                                scale: 3,
                                brushType: 'stroke'
                            },
                            // hoverAnimation: true,
                            itemStyle: {
                                color: '#cce198',
                                shadowBlur: 10,
                                shadowColor: '#333'
                            },
                            zlevel: 1,
                            symbolSize: 16, //图标大小
                            data: handleEvents.initSeriesData(opt.data).slice(1, 5) //数据
                        },
                        {
                            type: 'effectScatter',
                            coordinateSystem: 'geo',
                            showEffectOn: 'emphasis', //涟漪触发方式 render=始终显示 emphasis=hover时显示
                            rippleEffect: {
                                //涟漪特效
                                period: 4,
                                scale: 3,
                                brushType: 'stroke'
                            },
                            // hoverAnimation: true,
                            itemStyle: {
                                color: '#ffaf23',
                                shadowBlur: 10,
                                shadowColor: '#333'
                            },
                            zlevel: 1,
                            z: 5,
                            symbolSize: 16, //图标大小
                            data: handleEvents.initSeriesData(opt.data).slice(0, 1) //数据
                        }
                    ]
                }
                _this.chart.setOption(option)
                // 添加事件
                _this.chart.on('click', function (params) {
                    var _name = name[idx] == 'china' ? '中国' : name[idx]
                    var _self = this
                    if (opt.goDown && params.name !== _name) {
                        if (cityMap[params.name]) {
                            var url = cityMap[params.name]
                            // _this.getLatestTripByProvince(params.name)
                            _this.$axios.get(url).then((res) => {
                                curGeoJson = res.data
                                option.series[1].data = []
                                option.series[2].data = []
                                echarts.registerMap(params.name, curGeoJson)
                                handleEvents.resetOption(_self, option, params.name)
                            })
                        }
                    }
                    if (_name != '中国') {
                        _this.getLatestTrip()
                        handleEvents.resetOption(chart, option, 'china')
                    }
                })

                _this.chart.setMap = function (mapName) {
                    var _self = this
                    if (mapName.indexOf('市') < 0) mapName = mapName + '市'
                    var citySource = cityMap[mapName]
                    if (citySource) {
                        var url = citySource
                        _this.$axios.get(url).then((res) => {
                            curGeoJson = res.data
                            echarts.registerMap(mapName, curGeoJson)
                            handleEvents.resetOption(_self, option, mapName)
                        })
                    }
                }
                return _this.chart
            }

            // 渲染地图
            _this.myChartMap = echarts.extendsMap(id, opt)
        },
        initChartsMap() {
            var _this = this
            _this.$axios.get('/map/data-china.json').then((res) => {
                echarts.registerMap('china', res.data)

                if (_this.chart) {
                    _this.chart.dispose()
                }

                _this.createMap('container', {
                    bgColor: 'transparent', // 画布背景色
                    mapName: 'china', // 地图名
                    goDown: true, // 是否下钻
                    // 下钻回调
                    callback: function (name, option, instance) {},
                    data: _this.cityCount
                })
            })
        },
        //添加script标签
        appendScript(url) {
            var jsapi = document.createElement('script')
            jsapi.charset = 'utf-8'
            jsapi.src = url
            document.head.appendChild(jsapi)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container {
    width: 100%;
    height: 100%;
    border: none !important;
    outline: none;
}
.content {
    position: relative;
    width: 100%;
    height: 75%;
}
.slide_box {
    width: 2.6rem;
    min-width: 180px;
    height: 1.46rem;
    min-height: 70px;
    position: absolute;
    bottom: 0.3rem;
    left: 0.2rem;
    z-index: 99;
    background: url(../../public/images/msi/slide_box.png) no-repeat;
    background-size: 100% 100%;
    padding: 0.16rem 0.1rem 0;
}
/* .device_pixel_ratio .slide_box{
		width:3.1rem;
	}
	.device_pixel_ratio .route_list li,.device_pixel_ratio .hint_text p{
		width: 2.88rem;
	} */
.slide_title {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
}
.slide_carousel {
    width: 100%;
    height: 0.88rem;
    margin-top: 0.12rem;
}
.route_list li,
.hint_text p {
    width: 2.38rem;
    min-width: 170px;
    height: 0.38rem;
    line-height: 0.38rem;
    background: rgba(54, 153, 255, 0.2);
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #30fbfe;
    margin-bottom: 0.12rem;
    cursor: pointer;
    padding: 0 0.15rem;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.route_list span {
    /* width: 1.1rem;
		overflow:hidden;
		text-overflow:ellipsis;
		-o-text-overflow:ellipsis;
		white-space:nowrap; */
}
.route_list b {
    /* width: 1.28rem;
		overflow:hidden;
		text-overflow:ellipsis;
		-o-text-overflow:ellipsis;
		white-space:nowrap; */
}
.hint_text p {
    margin-top: 0.12rem;
    margin-bottom: 0;
    justify-content: center;
}
.route_list li.active {
    margin-bottom: 0;
}
/* @media screen and (max-height: 660px) {
		.slide_box{
			width: 3rem;
		}
		.route_list li,.hint_text p{
			width: 2.78rem;
		}
	} */
</style>
