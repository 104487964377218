<template>
    <div class="module_wrap">
        <div class="score_text">
            <b>驾驶评分前三&后三城市</b>
            <span>（分）</span>
        </div>
        <div class="line"></div>
        <div id="chart_top"></div>
        <div v-show="cityScores.length == 0" class="null_data_chart">暂无数据</div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    name: 'ScoreRanking',
    props: {
        cityScores: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            myChart: ''
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize()
                }, 500)
            }
        })
    },
    watch: {
        cityScores(value) {
            if (value && value.length > 0) {
                var response = {
                    avgScore: [],
                    cityName: []
                }
                value.forEach((val, key) => {
                    val.itemValue > 0 && (val.itemValue = val.itemValue.toFixed(1))
                    response.avgScore.push(val.itemValue)
                    response.cityName.push(val.itemKey)
                })
                this.loadCharts(response)
            }
        }
    },
    methods: {
        loadCharts(optionData) {
            var chartDom = document.getElementById('chart_top')
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' })
            var option = {
                xAxis: {
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#B8D3F1', //坐标值得具体的颜色
                            fontSize: 10
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(87, 206, 234, 0.1)'
                        }
                    },
                    data: optionData.cityName.slice(0, 6)
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#B8D3F1' //坐标值得具体的颜色
                        }
                    },
                    max: 100,
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(87, 206, 234, 0.1)'
                        },
                        show: false
                    }
                },
                tooltip: {
                    // trigger: 'axis'
                    formatter: '{b0}: {c0} 分',
                    show: true
                },
                grid: {
                    top: '13%',
                    bottom: '19%',
                    left: '12%',
                    right: '5%'
                },
                series: [
                    {
                        data: optionData.avgScore.slice(0, 6),
                        // data: [
                        //     {
                        //         value: optionData.avgScore[0]
                        //     },
                        //     {
                        //         value: optionData.avgScore[1]
                        //     },
                        //     {
                        //         value: optionData.avgScore[2]
                        //     },
                        //     {
                        //         value: optionData.avgScore[3],
                        //         itemStyle: {
                        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                 { offset: 0, color: 'rgba(64, 56, 125, 1)' },
                        //                 { offset: 1, color: 'rgba(209, 224, 234, 1)' }
                        //             ])
                        //         },
                        //         emphasis: {
                        //             itemStyle: {
                        //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                     { offset: 0, color: 'rgba(209, 224, 234, 1)' },
                        //                     { offset: 1, color: 'rgba(64, 56, 125, 1)' }
                        //                 ])
                        //             }
                        //         }
                        //     },
                        //     {
                        //         value: optionData.avgScore[4],
                        //         itemStyle: {
                        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                 { offset: 0, color: 'rgba(64, 56, 125, 1)' },
                        //                 { offset: 1, color: 'rgba(209, 224, 234, 1)' }
                        //             ])
                        //         },
                        //         emphasis: {
                        //             itemStyle: {
                        //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                     { offset: 0, color: 'rgba(209, 224, 234, 1)' },
                        //                     { offset: 1, color: 'rgba(64, 56, 125, 1)' }
                        //                 ])
                        //             }
                        //         }
                        //     },
                        //     {
                        //         value: optionData.avgScore[5],
                        //         itemStyle: {
                        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                 { offset: 0, color: 'rgba(64, 56, 125, 1)' },
                        //                 { offset: 1, color: 'rgba(209, 224, 234, 1)' }
                        //             ])
                        //         },
                        //         emphasis: {
                        //             itemStyle: {
                        //                 color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //                     { offset: 0, color: 'rgba(209, 224, 234, 1)' },
                        //                     { offset: 1, color: 'rgba(64, 56, 125, 1)' }
                        //                 ])
                        //             }
                        //         }
                        //     }
                        // ],
                        type: 'bar',
                        barWidth: '30%',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{@score}',
                            color: '#0399DA'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#0072FF' },
                                { offset: 1, color: '#02D5FF' }
                            ])
                        },
                        emphasis: {
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: '#02D5FF' },
                                    { offset: 1, color: '#0072FF' }
                                ])
                            }
                        }
                    }
                ]
            }
            this.myChart.setOption(option)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 32%;
    background: url(../../public/images/msi/box_1.png) no-repeat;
    background-size: 100% 100%;
    padding: 0.2rem;
    position: relative;
}
.score_text {
    font-size: 0.18rem;
}
.score_text span {
    font-size: 0.14rem;
}
.line {
    width: 1.86rem;
    height: 1px;
    background: url(../../public/images/msi/line.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 0.14rem;
}
#chart_top {
    width: 100%;
    position: absolute;
    top: 0.53rem;
    left: 0;
    bottom: 0;
    z-index: 60;
}
</style>
