<template>
    <div class="module_wrap">
        <div class="score_text">
            <b>车辆平均百公里三急一超TOP5城市</b>
            <span>（次）</span>
            <!-- <span>{{nowMonth}}</span> -->
        </div>
        <div class="line"></div>
        <!-- <div class="small_title">
		<span>全部车辆日平均行驶时长 </span>
		<b>{{allAvgDurationOfDay}}次</b>
	</div> -->
        <div class="times_top" v-if="topCities.length > 0">
            <ul>
                <li>
                    <b>{{ topCities[3].cityName }}</b>
                    <div>
                        <span>{{ topCities[3].alarmNum || 0 }}</span>
                        <span class="unit">次</span>
                    </div>
                </li>
                <li>
                    <b>{{ topCities[1].cityName }}</b>
                    <div>
                        <span>{{ topCities[1].alarmNum || 0 }}</span>
                        <span class="unit">次</span>
                    </div>
                </li>
                <li>
                    <b>{{ topCities[0].cityName }}</b>
                    <div>
                        <span>{{ topCities[0].alarmNum || 0 }}</span>
                        <span class="unit">次</span>
                    </div>
                </li>
                <li>
                    <b>{{ topCities[2].cityName }}</b>
                    <div>
                        <span>{{ topCities[2].alarmNum || 0 }}</span>
                        <span class="unit">次</span>
                    </div>
                </li>
                <li>
                    <b>{{ topCities[4].cityName }}</b>
                    <div>
                        <span>{{ topCities[4].alarmNum || 0 }}</span>
                        <span class="unit">次</span>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else class="null_data_chart">暂无数据</div>
    </div>
</template>

<script>
export default {
    name: 'TimesRanking',
    props: {
        cityAvgAlarm: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            topCities: [],
            allAvgDurationOfDay: ''
        }
    },
    watch: {
        cityAvgAlarm(value) {
            if (value && value.length > 0) {
                this.topCities = []
                value.forEach((item) => {
                    // item.alarmNum = item.itemValue ? item.itemValue.toFixed(2) : item.itemValue
                    item.alarmNum = item.itemValue
                    item.cityName = item.itemKey
                    this.topCities.push(item)
                })
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 33%;
    background: url(../../public/images/msi/box_1.png) no-repeat;
    background-size: 100% 100%;
    padding: 0.2rem;
    position: relative;
}
.score_text {
    font-size: 0.18rem;
}
.score_text span {
    font-size: 0.14rem;
}
.line {
    width: 1.86rem;
    height: 1px;
    background: url(../../public/images/msi/line.png) no-repeat;
    background-size: 100% 100%;
    margin: 0.14rem 0 0.1rem;
}
.small_title {
    font-size: 0.14rem;
    font-weight: 400;
    color: #ffffff;
}
.small_title b {
    color: #2adeea;
}
.times_top {
    width: 100%;
    position: absolute;
    top: 0.62rem;
    left: 0;
    bottom: 0;
    z-index: 60;
    display: flex;
    align-items: center;
    padding: 0 0.3rem;
}
.times_top ul {
    width: 100%;
    height: 80%;
    /* height: 1.8rem; */
    background: url(../../public/images/msi/top5.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
}

.times_top li {
    width: 17%;
    /* height:80%; */
    height: 0.96rem;
    font-size: 12px;
    font-weight: 500;
    color: #30fcff;
    text-align: center;
}
.times_top li:nth-child(1) {
    margin-top: 0.13rem;
}
.times_top li:nth-child(2) {
    margin-top: 0.04rem;
}
.times_top li:nth-child(3) {
    margin-top: -0.1rem;
}
.times_top li:nth-child(4) {
    margin-top: 0.04rem;
}
.times_top li:nth-child(5) {
    margin-top: 0.13rem;
}
.times_top li b {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.times_top li div {
    font-size: 0.2rem;
    margin-top: 0.6rem;
    display: block;
    font-family: Source Han Sans CN;
}
@media screen and (max-height: 480px) {
    .times_top li div {
        margin-top: 0.8rem;
    }
}
@media screen and (max-height: 380px) {
    .times_top li div {
        margin-top: 1.2rem;
    }
}
.times_top li .unit {
    font-size: 12px;
}
</style>
