<template>
    <div class="module_wrap">
        <div class="score_text">
            <b>日平均行驶时长TOP5城市</b>
            <span>（小时）</span>
        </div>
        <div class="line"></div>
        <div class="times_top" v-if="cityAvgTime.length > 0">
            <ul>
                <li v-for="(item, index) in topCities" :key="index">
                    <div>
                        <span class="active">{{ item.itemValue || 0 }}</span>
                        <span>小时</span>
                    </div>
                    <b>{{ item.itemKey || '暂无数据' }}</b>
                </li>
            </ul>
        </div>
        <div v-else class="null_data_chart" style="padding-top: 0.4rem">暂无数据</div>
    </div>
</template>

<script>
export default {
    name: 'AbnormalRanking',
    props: {
        cityAvgTime: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            topCities: [],
            newNumber: 0
        }
    },
    watch: {
        cityAvgTime(value) {
            if (value && value.length > 0) {
                value.forEach((item) => {
                    var avgDurationOfDay = parseFloat(item.itemValue)
                    avgDurationOfDay > 0 && (item.itemValue = avgDurationOfDay.toFixed(2))
                    avgDurationOfDay > 10 && (item.itemValue = avgDurationOfDay.toFixed(1))
                })
                this.topCities = value
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 24%;
    padding: 0.2rem 0.2rem 0;
    position: relative;
    background: url(../../public/images/msi/box_6.png) no-repeat;
    background-size: 100% 100%;
}
.score_text {
    font-size: 0.18rem;
}
.score_text span {
    font-size: 0.14rem;
}
.line {
    width: 1.86rem;
    height: 1px;
    background: url(../../public/images/msi/line.png) no-repeat;
    background-size: 100% 100%;
    margin: 0.14rem 0;
}
.small_title {
    font-size: 0.14rem;
    font-weight: 400;
    color: #ffffff;
}
.small_title b {
    color: #2adeea;
}
.times_top {
    /* position: relative; */
    width: 100%;
    position: absolute;
    top: 0.53rem;
    left: 0;
    bottom: 0;
    z-index: 60;
    display: flex;
    align-items: center;
    padding: 0 0.3rem;
}
.times_top ul {
    display: flex;
    justify-content: space-between;
    background: url(../../public/images/msi/city_bg.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 68%;
}
.times_top li {
    width: 20%;
    font-size: 12px;
    font-weight: 500;
    color: #b8d3f1;
    text-align: center;
    padding-top: 0.1rem;
}
.times_top li div {
    margin-bottom: 0.1rem;
    font-family: Source Han Sans CN;
    color: #30fdff;
}
.times_top li .active {
    font-size: 13px;
}
</style>
