<template>
    <div class="wrap">
        <my-header @clickMenu="clickMenu" :checkedMenu="checkedMenu"></my-header>
        <div class="main">
            <el-row>
                <el-col :span="6">
                    <div class="now_date">{{ nowDate }}</div>
                    <div class="left_title">
                        业务质量 <span>（{{ nowMonth }}月份）</span>
                    </div>
                    <div class="chart_left">
                        <!-- 驾驶评分分布 -->
                        <score-distribution :scoreDist="homeModuleData.scoreDist"></score-distribution>
                        <!-- 驾驶评分前三&后三城市 -->
                        <score-ranking :cityScores="homeModuleData.cityScores"></score-ranking>
                        <!--  车辆平均百公里三急一超TOP5城市-->
                        <abnormal-ranking :cityAvgAlarm="homeModuleData.cityAvgAlarm"></abnormal-ranking>
                    </div>
                </el-col>
                <el-col :span="12">
                    <!-- 统计数据 -->
                    <statistic-data></statistic-data>
                    <!-- 中国地图 -->
                    <china-map v-show="mapVisible" :cityDist="homeModuleData.cityDist" :provinceDist="homeModuleData.provinceDist" :devicePixelRatio="devicePixelRatio"></china-map>
                </el-col>
                <el-col :span="6">
                    <div class="right_title">
                        车辆分析 <span>（{{ nowMonth }}月份）</span>
                    </div>
                    <div class="chart_right">
                        <!-- 日平均行驶里程前三&后三城市 -->
                        <mileage-ranking :cityAvgMile="homeModuleData.cityAvgMile"></mileage-ranking>
                        <!-- 日平均行驶时长TOP5城市 -->
                        <times-ranking :cityAvgTime="homeModuleData.cityAvgTime"></times-ranking>
                        <!-- 出行时段分布 -->
                        <travel-time :timeDist="homeModuleData.timeDist"></travel-time>
                    </div>
                    <!-- 平均出行天数TOP3城市 -->
                    <!-- <days-ranking></days-ranking> -->
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import ScoreDistribution from '@/components/ScoreDistribution.vue';
import ChinaMap from '@/components/ChinaMap.vue';
import ScoreRanking from '@/components/ScoreRanking.vue';
import TimesRanking from '@/components/TimesRanking.vue';
import MileageRanking from '@/components/MileageRanking.vue';
import TravelTime from '@/components/TravelTime.vue';
import AbnormalRanking from '@/components/AbnormalRanking.vue';
import DaysRanking from '@/components/DaysRanking.vue';
import StatisticData from '@/components/StatisticData.vue';
// import DevicePixelRatio from '@/assets/js/devicePixelRatio';
export default {
    name: 'Home',
    components: {
        MyHeader,
        ScoreDistribution,
        ChinaMap,
        ScoreRanking,
        TimesRanking,
        MileageRanking,
        TravelTime,
        AbnormalRanking,
        DaysRanking,
        StatisticData
    },
    data() {
        return {
            devicePixelRatio: 1,
            checkedMenu: { name: '首页', id: '1' },
            drivingScore: [],
            nowMonth: '',
            mapVisible: true,
            timer: null,
            nowDate: '', // 当前日期时间
            formatDateTimer: null,
            totalData: {
                linevehicle: '',
                totalhour: '',
                totalmile: 0,
                totalvehicle: ''
            },
            homeModuleData: {
                areaDist: [], // 区域驾驶情况分布
                cityAvgAlarm: [], // 城市平均3急1超数量排行
                cityAvgMile: [], // 城市平均里程排行
                cityAvgTime: [], // 城市平均驾驶时长排行
                cityScores: [], // 城市评分排行
                cityScores: [], // 车辆评分分布
                cityScores: [] // 出行时长分布
            }
        };
    },
    methods: {
        // 首页仪表数据
        getHomeModuleData() {
            this.instance.get('/ubi/api/home/dashboard', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.homeModuleData = data.data;
                    // console.log(this.homeModuleData.cityScores)
                }
            });
        },
        currentTime() {
            this.formatDateTimer = setInterval(this.formatDate, 1000);
        },
        formatDate() {
            let date = new Date();
            let year = date.getFullYear(); // 年
            let month = date.getMonth() + 1; // 月
            let day = date.getDate(); // 日
            let week = date.getDay(); // 星期
            let weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            let hour = date.getHours(); // 时
            hour = hour < 10 ? '0' + hour : hour; // 如果只有一位，则前面补零
            let minute = date.getMinutes(); // 分
            minute = minute < 10 ? '0' + minute : minute; // 如果只有一位，则前面补零
            let second = date.getSeconds(); // 秒
            second = second < 10 ? '0' + second : second; // 如果只有一位，则前面补零
            this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        },
        clickMenu(item) {
            clearInterval(this.timer);
            this.mapVisible = this.$route.path != '/Home' ? false : true;
            this.$router.push({
                path: item.linkage
            });
        },
        // 获取上一个月月份
        setMonth() {
            let tmpMonth = new Date().getMonth();
            tmpMonth = tmpMonth == 0 ? 12 : tmpMonth;
            return tmpMonth;
        }
    },
    mounted() {
        this.nowMonth = this.setMonth();
        this.devicePixelRatio = window.devicePixelRatio || 1;
        this.currentTime();
        window.addEventListener('resize', () => {
            this.devicePixelRatio = window.devicePixelRatio || 1;
        });
        this.getHomeModuleData();
    },
    destroyed() {
        if (this.formatDateTimer) {
            clearInterval(this.formatDateTimer); // 在Vue实例销毁前，清除时间定时器
        }
    }
};
</script>
<style scoped>
.wrap {
    background: url(../../public/images/msi/home_bg.jpg) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: relative;
    color: #fff;
    line-height: 1;
}
.main {
    position: absolute;
    top: 0.8rem;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    overflow: hidden;
    padding: 0 0.3rem;
}
.el-row,
.el-col {
    position: relative;
    height: 100%;
}
.chart_left,
.chart_right {
    width: 100%;
    position: absolute;
    top: 0.9rem;
    bottom: 0.3rem;
    left: 0;
    z-index: 50;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}
.chart_right {
    left: auto;
    right: 0;
    top: 0.8rem;
}
.left_title,
.right_title {
    color: #fff;
    width: 99%;
    height: 0.32rem;
    line-height: 0.32rem;
    text-align: center;
    background: url(../../public/images/msi/title_box.png) no-repeat;
    background-size: 100% 100%;
    font-size: 0.18rem;
    margin: 0 auto;
}
.right_title {
    margin-top: 0.3rem;
}
.left_title span,
.right_title span {
    font-size: 12px;
}
.now_date {
    color: #30fbfe;
    width: 100%;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: center;
}
</style>