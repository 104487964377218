<template>
    <div class="lay_out">
        <my-header v-if="!$route.query.redirect" @handleFoldMenus="handleFoldMenus" @clickMenu="clickMenu" :checkedMenu="checkedMenu" v-heightResize="heightResize"></my-header>
        <menus v-if="!$route.query.redirect" :isCollapse="isCollapse" :checkedMenu="checkedMenu" v-resize="MenuResize"></menus>
        <div class="main" :class="{ hideSidebar: !$route.query.redirect ? isCollapse : true }">
            <!-- <div ref="iframe" class="iframe">
                <transition name="fade-transform" mode="out-in">
                    <router-view v-if="isVisible" :isCollapse="isCollapse" />
                </transition>
            </div> -->
            <router-view v-if="isVisible" :isCollapse="isCollapse" />
        </div>
    </div>
</template>
<script>
import MyHeader from '@/components/MyHeader.vue'
import Menus from '@/components/Menus.vue'

export default {
    name: 'Layout',
    components: {
        MyHeader,
        Menus
    },
    computed: {},
    data() {
        return {
            isCollapse: false,
            isVisible: true,
            containerWidth: 0,
            containerLeft: 0,
            defaultActive: '1-1',
            checkedMenu: { name: '设备管理', id: '30' },
            menuData: [
                {
                    id: 1,
                    name: '用户',
                    child: [
                        {
                            id: '1-1',
                            parent: 1,
                            path: '/vue/userManage',
                            name: '用户管理'
                        },
                        {
                            id: '1-2',
                            parent: 1,
                            path: '/vue/roleAdmin',
                            name: '角色管理'
                        },
                        {
                            id: '1-3',
                            parent: 1,
                            path: '/vue/deptAdmin',
                            name: '组织架构管理'
                        }
                    ]
                }
            ]
        }
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.containerWidth = document.body.clientWidth - this.containerLeft
                const rightDom = document.getElementById('sub')
                rightDom.style.width = this.containerWidth + 'px'
            })()
        }

        var menuVal = sessionStorage.getItem('menuVal')
        if (menuVal) {
            this.defaultActive = menuVal
            this.showPage = menuVal.split('-')[1]
        }
    },
    // 监听左侧菜单自动被撑开时宽度变化
    directives: {
        resize: {
            // 指令的名称
            bind(el, binding) {
                // el为绑定的元素，binding为绑定给指令的对象
                let _width = ''
                let _height = ''
                function isReize() {
                    const style = document.defaultView.getComputedStyle(el)
                    if (_width !== style.width) {
                        binding.value({ width: style.width, height: style.height, targetId: el.id })
                        _width = style.width
                    }
                }
                el.__vueSetInterval__ = setInterval(isReize, 300)
            },
            unbind(el) {
                clearInterval(el.__vueSetInterval__)
            }
        },
        heightResize: {
            // 指令的名称
            bind(el, binding) {
                // el为绑定的元素，binding为绑定给指令的对象
                let _width = ''
                let _height = ''
                function isReize() {
                    const style = document.defaultView.getComputedStyle(el)
                    if (_width !== style.width) {
                        binding.value({ width: style.width, height: style.height, targetId: el.id })
                        _width = style.width
                    }
                }
                el.__vueSetInterval__ = setInterval(isReize, 300)
            },
            unbind(el) {
                clearInterval(el.__vueSetInterval__)
            }
        }
    },
    methods: {
        handleFoldMenus(isFold) {
            this.isCollapse = isFold
        },
        MenuResize(data) {
            // data = {width:'64px', height:'43.19px' targetId:''}
            // 动态设置子应用margin-left
            const rightDom = document.getElementById('sub')
            let tmpIndex = data.width.indexOf('p')
            let widthStr = data.width.slice(0, tmpIndex)
            this.containerLeft = Number(widthStr)
            rightDom.style.marginLeft = Number(widthStr) / 2 + 'px'
            rightDom.style.width = document.body.clientWidth - Number(widthStr) + 'px'
        },
        heightResize(data) {
            // data = {width:'64px', height:'43.19px' targetId:''}
            const rightDom = document.getElementById('sub')
            let tmpHeightIndex = data.height.indexOf('p')
            let heightStr = data.height.slice(0, tmpHeightIndex)
            rightDom.style.marginTop = Number(heightStr) / 2 + 'px'
            rightDom.style.height = document.body.clientHeight - Number(heightStr) + 'px'
        },
        clickMenu(checkedRoutes) {
            this.checkedMenu = checkedRoutes
        },
        clickMenuTwo(val) {
            this.defaultActive = val
            this.showPage = 99
            setTimeout(() => {
                this.showPage = val.split('-')[1]
                sessionStorage.setItem('menuVal', val)
            }, 200)

            this.menuData.forEach((ele) => {
                if (ele.id != val && ele.child) {
                    ele.child.forEach((item) => {
                        if (item.id == val) {
                            this.$router.push({ path: item.path })
                        }
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
.lay_out {
    min-height: 100%;
    position: relative;
}
.lay_out.homepage {
    background-color: rgb(38, 45, 60);
}
.main {
    position: absolute;
    top: 0.8rem;
    bottom: 0;
    left: 200px;
    right: 0;
    z-index: 10;
    background-color: #f2f8fc;
    transition: all 0.6s;
    padding: 0.3rem 0.4rem;
}
.hideSidebar {
    left: 64px;
}
.iframe {
    width: 100%;
    height: 100%;
    overflow: auto;
}
</style>