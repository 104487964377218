<template>
    <div class="module_wrap">
        <div class="times_top">
            <ul>
                <li>
                    <countTo class="totla_num" v-if="staticsDataFlag" :startVal="numDataStart" :endVal="totalData.deviceNum" :duration="500"></countTo>
                    <span class="totla_num" v-else>0</span>
                    <div>
                        <span class="active">设备总数</span>
                        <span>（台）</span>
                    </div>
                    <div class="total_icon"><img src="../../public/images/msi/total_icon1.png" /></div>
                </li>
                <li>
                    <countTo class="totla_num" v-if="staticsDataFlag" :startVal="numDataStart" :endVal="totalData.totalMileage" :duration="500"></countTo>
                    <span class="totla_num" v-else>0</span>
                    <div>
                        <span class="active">总行驶里程</span>
                        <span>（千米）</span>
                    </div>
                    <div class="total_icon"><img src="../../public/images/msi/total_icon2.png" /></div>
                </li>
                <li>
                    <countTo class="totla_num" v-if="staticsDataFlag" :startVal="numDataStart" :endVal="totalData.vehicleNum" :duration="500"></countTo>
                    <span class="totla_num" v-else>0</span>
                    <div>
                        <span class="active">车辆总数</span>
                        <span>（辆）</span>
                    </div>
                    <div class="total_icon"><img src="../../public/images/msi/total_icon3.png" /></div>
                </li>
                <li>
                    <countTo class="totla_num" v-if="staticsDataFlag" :startVal="numDataStart" :endVal="totalData.totalDuration" :duration="500"></countTo>
                    <span class="totla_num" v-else>0</span>
                    <div>
                        <span class="active">总行驶时长</span>
                        <span>（小时）</span>
                    </div>
                    <div class="total_icon"><img src="../../public/images/msi/total_icon4.png" /></div>
                </li>
                <li>
                    <countTo class="totla_num" v-if="staticsDataFlag" :startVal="numDataStart" :endVal="totalData.tripsNum" :duration="500"></countTo>
                    <span class="totla_num" v-else>0</span>
                    <div>
                        <span class="active">总行程数量</span>
                        <span>（条）</span>
                    </div>
                    <div class="total_icon"><img src="../../public/images/msi/total_icon5.png" /></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
    //
    name: 'StatisticData',
    props: {
        nowMonth: String
    },
    components: { countTo },
    data() {
        return {
            staticsDataFlag: false,
            newNumber: 0,
            timer: null,
            numDataStart: 0,
            totalData: {
                totalDevicesNum: 0,
                totalMileage: 0,
                totalVehiclesNum: 0,
                totalDuration: 0,
                totalTripsNum: 0
            }
        }
    },
    mounted() {
        this.getStaticsAllData()
    },
    methods: {
        getStaticsAllData() {
            this.staticsDataFlag = false
            this.instance.get('/ubi/api/home/summary').then((res) => {
                let data = res.data
                if (data.code == 200) {
                    let tmpData = data.data
                    Object.keys(tmpData).forEach((key) => {
                        tmpData[key] = parseInt(tmpData[key])
                    })
                    this.totalData = tmpData
                    this.staticsDataFlag = true
                }
            })
        }
    },
    destroyed() {
        clearInterval(this.timer)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 90%;
    height: 25%;
    padding-top: 0.4rem;
    margin: 0 auto;
}
.times_top {
    height: 100%;
    background: url(../../public/images/msi/total_bg.png) no-repeat;
    background-size: 100% 100%;
    padding: 0 2.6%;
}
.times_top ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.times_top li {
    width: 20%;
    height: 100%;
    font-size: 0.16rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    position: relative;
}
.total_icon {
    width: 0.44rem;
    height: 0.36rem;
    position: absolute;
    bottom: 33%;
    left: 50%;
    z-index: 90;
    transform: translate(-50%, 0);
}
.times_top li .totla_num {
    font-size: 0.32rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #23fffc;
    margin-bottom: 0.1rem;
    display: block;
}
.times_top li .active {
    font-size: 0.2rem;
    display: block;
    margin-bottom: 0.05rem;
}
</style>
