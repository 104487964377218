import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store'
import Layout from '@/layout'
import {getToken,intervalRefreshToken,setTimeToken,getTimeToken} from '../utils/auth.js'
Vue.use(VueRouter)
var baseTitle=store.getters.baseTitle

let routes = [
  {
    path: '/vue/*',
    component: Layout,
  },
    {
      path: '/',
      name: 'Login',
        showHidden:true,
      meta: {
        title: '宏瓴科技',
        showHidden:true,
        menuId:1
      },
      component: Login
    }
]

// 动态重置路由
function resetRoutes(routesData) {
  routes = [
    {
      path: '/vue/*',
      component: Layout,
    },
    {
      path: '/',
      name: 'Login',
        showHidden:true,
      meta: {
        title: '宏瓴科技',
        showHidden:true,
        menuId:1
      },
      component: () => import('../views/Login.vue')
    },
    // {
    //   path: '/home',
    //   name: 'home',
    //   meta: {
    //     title: '首页',
    //     icon: 'el-icon-s-tools',
    //     menuId: 2
    //   },
    //   component: Home
    // },
    {
      path: '/vehicleMap',
      name: 'vehicleMap',
      meta: {
        title: '行程轨迹',
        showHidden:true,
        menuId:3
      },
      component: () => import('../views/maps/vehicleMap.vue')
    }
  ]
  let userMenu = JSON.parse(localStorage.getItem("userMenu")) || [];//路由数据
	for (let i = 0; i < userMenu.length; i++) {
    let tmpUserMenu = userMenu[i]
		let tmpRoute = {
			path: tmpUserMenu.path,
			component: Layout,
			name: tmpUserMenu.path.slice(1, tmpUserMenu.path.length),
			meta: { title: tmpUserMenu.menuName, icon: 'el-icon-s-tools', menuId: tmpUserMenu.menuId },
			children: []
    }
    
		let childrenItem = {}
    if (tmpUserMenu.children && tmpUserMenu.children.length > 0) {
      for (let t = 0; t < tmpUserMenu.children.length; t++) {
        const tmpItem = tmpUserMenu.children[t];
        tmpItem.path = tmpItem.path.slice(1, tmpItem.path.length)
				childrenItem = {
					path: tmpItem.path,
					name: tmpItem.path,
          meta: {
            title: tmpItem.menuName,
            icon: 'el-icon-s-marketing',
            menuId: tmpItem.menuId,
            perms: tmpItem.perms != tmpUserMenu.menuName?tmpItem.perms:null,
            component: tmpItem.component
          }
        }

        if (tmpUserMenu.path != '/vue') {
          // childrenItem.component = () => import('@/views' + tmpItem.component + '/' + tmpItem.path + '.vue')
          childrenItem.component = (resolve) => require(['@/views' + tmpItem.component + '/' + tmpItem.path + '.vue'], resolve)
        }
				tmpRoute.children.push(childrenItem)
			}

    } else {
			// tmpRoute.component = () => import('@/views' + tmpUserMenu.path + '/' + tmpItem.path + '.vue')
      tmpRoute.component = (resolve) => require(['@/views' + tmpUserMenu.path + '.vue'], resolve)
		}
    routes.push(tmpRoute)
	}
  // console.log('整理路由：')
  // console.log(routes)
  localStorage.setItem('routes', JSON.stringify(routes))
}

let router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let routerTime = setInterval(() => {
  if (localStorage.getItem('userMenu')) {
    clearInterval(routerTime)
    resetRoutes()
    routes.forEach(v => { 
      router.addRoute(v)
    })
  }
}, 500)

// 防止重复跳转
const originalPush = VueRouter.prototype.push 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err) 
}


// 路由守卫  
router.beforeEach((to, from, next) => {

  if(to.meta.title){
    document.title = to.meta.title
  }

  // 检测path首字母是否大写字母
  to.path != '/Home' && pathNameCapitalization(to.path)

  if (to.fullPath.indexOf('redirect') != -1 && localStorage.getItem('tokenError') == 401) { 
      next({
        replace:true,
        name: '首页',
        path:'/'
      })
  }

  if (to.path == '/vue/') { 
      next({
        replace:true,
        name: '首页',
        path:'/'
      })
  }

  if (getToken()) {
    next()
  } else {
    if (to.path == '/') {
		  next()
    }else{
      next({
        replace:true,
        name:'Login',
      })
    }
  }
})


function pathNameCapitalization(routePath) {
  let tmpPath = routePath
  let tmpLastIndex = tmpPath.lastIndexOf('/')
  let path = tmpPath.slice(tmpLastIndex + 1, tmpPath.length)
  let capitalization = /^[A-Z]/.test(path)
  if (capitalization) {
    localStorage.clear()
    sessionStorage.clear()
    location.href = '/'

  }
}


export function handleMenuData(data) {
  localStorage.removeItem('headerMenus')
  localStorage.removeItem('routes')
	data && resetRoutes(data)
}

export default router
