<template>
    <div class="module_wrap">
        <div class="score_text">
            <b>出行时段分布</b>
            <span>（占比）</span>
        </div>
        <div class="line"></div>
        <div id="travel_chart"></div>
        <div v-show="chartsResponse" class="null_data_chart">暂无数据</div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    name: 'TravelTime',
    props: {
        timeDist: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            myChart: '',
            newNumber: 0,
            chartsResponse: false
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize()
                }, 500)
            }
        })
    },
    watch: {
        timeDist(val) {
            if (val && val.length > 0) {
                this.loadCharts()
            }
            this.chartsResponse = val && val.length > 0 ? false : true
        }
    },
    methods: {
        loadCharts() {
            var chartDom = document.getElementById('travel_chart')
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' })
            let tmpDistObj = {}
            this.timeDist.length > 0 &&
                this.timeDist.forEach((ele) => {
                    ele.value = ele.val ? Number(ele.val.toFixed(1)) : ele.val
                    ele.name = ele.timeSpan
                    tmpDistObj[ele.name] = { name: ele.name, value: ele.value }
                })
            let tmpDist = []
            // 后台反的数据不一定是提示顺序，需要固定顺序进行处理
            let tips = ['7-9时', '9-12时', '12-17时', '17-19时', '19-24时', '0-4时', '4-7时']
            tips.forEach((item) => {
                tmpDist.push(tmpDistObj[item])
            })
            var option = {
                color: ['#488CF7', '#38CAFB', '#4CAFF9', '#4ADFCB', '#2AAEFA', '#0271F4', '#1D3F7F'],
                tooltip: {
                    formatter: '{b0} : {c0}小时 ',
                    show: true
                },
                legend: {
                    orient: 'vertical',
                    top: 'middle',
                    left: 'right',
                    itemGap: 5,
                    itemWidth: 14,
                    textStyle: {
                        color: '#BAC3C4',
                        fontSize: '0.16rem'
                    }
                },
                series: [
                    {
                        // name: 'Nightingale Chart',
                        type: 'pie',
                        radius: ['24%', '39%'],
                        center: ['39%', '50%'],
                        label: {
                            color: '#BAC3C4',
                            textStyle: {
                                fontSize: 12
                            },
                            formatter(target) {
                                return target.percent.toFixed(1) + '%'
                            }
                        },
                        data: tmpDist
                    }
                ]
            }
            this.myChart.setOption(option)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 39%;
    padding: 0.2rem 0.2rem 0;
    position: relative;
    background: url(../../public/images/msi/box_5.png) no-repeat;
    background-size: 100% 100%;
}
.score_text {
    font-size: 0.18rem;
    padding-left: 0.2rem;
}
.score_text span {
    font-size: 0.14rem;
}
.line {
    width: 1.86rem;
    height: 1px;
    background: url(../../public/images/msi/line.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 0.14rem;
    margin-left: 0.2rem;
}
#travel_chart {
    width: 97%;
    position: absolute;
    top: 0.53rem;
    left: 0;
    bottom: 0;
    z-index: 60;
}
</style>
