<template>
    <div class="menu">
        <el-menu :default-active="activeMenu" :collapse="isCollapse" background-color="#0B0F35" text-color="#fff" :unique-opened="true" :collapse-transition="true" mode="vertical">
            <template v-for="item in routes">
                <el-submenu v-if="item.children" ref="subMenu" :index="item.meta && item.meta.component ? item.meta.component : item.path" :key="item.meta.menuId" popper-append-to-body>
                    <template slot="title" v-if="item.meta">
                        <i :class="item.meta.icon"></i>
                        <span>{{ item.meta.title }}</span>
                    </template>
                    <el-menu-item v-for="child in item.children" :key="child.meta.menuId" @click.native="linkProps(child, item)" :index="resolvePath(item.path, child.path)">{{ child.meta.title }}</el-menu-item>
                </el-submenu>
                <el-menu-item v-else-if="item.meta" :key="item.meta.menuId" :index="resolvePath(item.path, item.path)" @click.native="linkProps(item, item.path)">
                    <i :class="item.meta && item.meta.icon ? item.meta.icon : ''" style="color: #fff"></i>
                    <span slot="title">{{ item.meta.title }}</span>
                </el-menu-item>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { EventBus } from '../assets/js/EventBus.js'
import path from 'path'
export default {
    name: 'MyMenus',
    props: {
        defaultActive: {
            type: String,
            default: '1-1'
        },
        isCollapse: {
            type: Boolean,
            default: true
        },
        checkedMenu: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    computed: {
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        }
    },
    data() {
        return {
            role: 1,
            fullscreenLoading: false, //全屏Loading
            passwordDialogVisible: false, //修改密码
            //  isCollapse:false,        //菜单是否收起
            infoVisible: false, //个人中心模态框是否显示
            isFullScreen: false, //是否全屏
            active: '',
            basePath: '',
            routes: []
            // defaultActive:'1-1',          //默认展开第几个菜单
        }
    },
    mounted() {
        this.setCheckedMenus()
    },
    methods: {
        setCheckedMenus() {
            let _this = this
            let checkedMenuTimer = setInterval(() => {
                if (localStorage.getItem('checkedMenu')) {
                    clearInterval(checkedMenuTimer)
                    localStorage.getItem('checkedMenu') ? '' : console.log('routes获取失败')
                    let routes = [JSON.parse(localStorage.getItem('checkedMenu'))]
                    let levelMenu = { children: [] }
                    let levelMenu1 = { children: [] }
                    routes.forEach((val, key) => {
                        if (val.children) {
                            let i = -1
                            val.children.forEach((value, index) => {
                                if (value.visible === false) {
                                    i = index
                                }
                                // 根据perms字段，拆分左侧二级菜单
                                if (value.meta.perms) {
                                    levelMenu1.name = value.name
                                    levelMenu1.path = val.path
                                    levelMenu1.meta = JSON.parse(JSON.stringify(value.meta))
                                    levelMenu1.meta.title = value.meta.perms
                                    levelMenu1.children.push(value)
                                } else {
                                    levelMenu.path = val.path
                                    levelMenu.name = val.name
                                    levelMenu.meta = routes[0].meta
                                    levelMenu.children.push(value)
                                }
                            })
                            if (i > -1) {
                                val.children.splice(i, 1)
                            }
                        }
                    })
                    _this.routes = []
                    levelMenu.children.length > 0 && _this.routes.push(levelMenu)
                    levelMenu1.children.length > 0 && _this.routes.push(levelMenu1)
                }
            }, 300)
        },
        linkProps(child, parent) {
            this.$router.push({
                path: typeof parent == 'string' ? child.path : parent.path + '/' + child.path
            })
        },
        resolvePath(basePath, routePath) {
            return path.resolve(basePath, routePath)
        }
    },
    watch: {
        $route(val) {
            this.setCheckedMenus()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu_leval1 {
    display: flex;
    align-items: center;
}
.menu_leval1 div {
    width: 20px;
    margin-right: 10px;
}
.menu {
    position: absolute;
    top: 0.8rem;
    left: 0;
    bottom: 0;
    /* width: 200px; */
    display: flex;
    display: -webkit-flex;
    flex-flow: row wrap;
    z-index: 10;
}
.el-menu {
    border: 0;
    width: 200px;
}
.el-menu--collapse.el-menu {
    width: 64px;
}
/deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
}
.menu .el-icon-model_1 {
    width: 20px !important;
    height: 20px;
    /* background: url(../../public/images/ICon2.png) no-repeat; */
    background-size: 100% 100%;
}
/deep/ .el-menu-item:hover,
/deep/ .el-submenu__title:hover {
    background-color: rgb(255, 255, 255, 0.2) !important;
}
/deep/.el-submenu__title i {
    color: #fff;
}
/deep/.el-submenu .el-menu-item {
    height: 40px;
    line-height: 40px;
}
</style>
