<template>
    <div class="module_wrap">
        <div class="score_text">
            <b>日平均行驶里程前三&后三城市</b>
            <span>（千米）</span>
        </div>
        <div class="line"></div>
        <div id="city_top"></div>
        <div v-show="cityAvgMile.length == 0" class="null_data_chart">暂无数据</div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    name: 'ScoreRanking',
    props: {
        cityAvgMile: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            myChart: '',
            newNumber: 0
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize()
                }, 500)
            }
        })
    },
    watch: {
        cityAvgMile(value) {
            if (value && value.length > 0) {
                var response = {
                    avgMileageOfDay: [],
                    cityName: []
                }
                value.forEach((val, key) => {
                    val.itemValue > 0 && (val.itemValue = val.itemValue.toFixed(2))
                    response.avgMileageOfDay.push(val.itemValue)
                    response.cityName.push(val.itemKey)
                })
                this.loadCharts(response)
            }
        }
    },
    methods: {
        loadCharts(optionData) {
            var chartDom = document.getElementById('city_top')
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' })
            var option = {
                xAxis: {
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#B8D3F1', //坐标值得具体的颜色
                            fontSize: 10
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(87, 206, 234, 0.1)'
                        }
                    },
                    data: optionData.cityName.slice(0, 6)
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#B8D3F1' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(87, 206, 234, 0.1)'
                        },
                        show: false
                    }
                },
                tooltip: {
                    // trigger: 'axis'
                    formatter: '{b0}: {c0} 千米',
                    show: true
                },
                grid: {
                    top: '12%',
                    bottom: '19%',
                    left: '13%',
                    right: '5%'
                },
                series: [
                    {
                        data: optionData.avgMileageOfDay.slice(0, 6),
                        type: 'bar',
                        barWidth: '40%',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{@score}',
                            color: '#0399DA'
                        },
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: 'rgba(1, 255, 246, 1)' },
                                { offset: 1, color: 'rgba(1, 255, 246, 0.1)' }
                            ])
                        },
                        emphasis: {
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: 'rgba(1, 255, 246, 0.1)' },
                                    { offset: 1, color: 'rgba(1, 255, 246, 1)' }
                                ])
                            }
                        }
                    }
                ]
            }
            this.myChart.setOption(option)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 34%;
    padding: 0.2rem 0.2rem 0;
    position: relative;
    background: url(../../public/images/msi/box_4.png) no-repeat;
    background-size: 100% 100%;
}
.score_text {
    font-size: 0.18rem;
}
.score_text span {
    font-size: 0.14rem;
}
.line {
    width: 1.86rem;
    height: 1px;
    background: url(../../public/images/msi/line.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 0.14rem;
}
#city_top {
    width: 100%;
    position: absolute;
    top: 0.53rem;
    left: 0;
    bottom: 0;
    z-index: 60;
}
</style>
