const refreshTokenKey = 'refreshToken'
//获取token 
var getToken=function(){
    return localStorage.getItem('AUTH_TOKEN');
}
//定时刷新token
var intervalRefreshToken=function(){
	setInterval(function(){
	    getTimeToken(refreshTokenKey,1000*60*60*2);
	},1000*60*2);
}
//设置带时间的token 
var setTimeToken=function(value){
    var curTime = new Date().getTime();
    localStorage.setItem(refreshTokenKey,JSON.stringify({data:value,time:curTime}));
}
//获取带时间的token，并传入时间差exp  
var getTimeToken=function(key,exp){
    var data = localStorage.getItem(key);
    var dataObj = JSON.parse(data);
    if (new Date().getTime() - dataObj.time>exp) {
        var refreshToken=dataObj.data;
		this.instance.get('/login/login',{
			params:{username:"admin",password:"admin"}
		}).then(res=>{
			var data=res.data;
			if(data.code==1){
				setTimeToken('refreshToken');
				Vue.prototype.$axios.defaults.headers.common['token'] =data.data.token.token;
				localStorage.setItem("AUTH_TOKEN",data.data.token.token);
			}
		});
        // $.ajax({
        //     type: 'GET',   //POST/PUT/DELETE/HEAD...
        //     url: globalTest+"refresh-t",
        //     beforeSend: function(request) {
        //         request.setRequestHeader("Refresh-t",refreshToken);
        //     },
        //     dataType: 'json',	//text/script/xml/json/jsonp
        //     success: function (data) {
        //         console.log(data);
        //         if(data.success==1){
        //             localStorage.setItem("serviceToken",data.token);
        //             Vue.prototype.$axios.defaults.headers.common['Authorization'] =data.token;
        //             setTimeToken(refreshToken);
        //         }
        //     },
        //     error: function(data){
        //         console.log("error: "+data);
        //     }//响应失败的回调  
        // });
    }
}

var tableHeight = function () {
 let heights = 0;
    let heightlen = 0;
    let classnameExists = !!document.getElementsByClassName("el-pagination");
    heightlen = classnameExists ? 170 : 0;
 
    this.heights = window.innerHeight - this.$refs.multipleTable.$el.offsetTop - heightlen;
    window.onresize = () => {
        this.heights =
            window.innerHeight - this.$refs.multipleTable.$el.offsetTop - heightlen;
    };
}
export {getToken,intervalRefreshToken,setTimeToken,getTimeToken,tableHeight}