<template>
    <div class="module_wrap">
        <div class="score_text">
            <b>驾驶评分分布</b>
            <span>（评分越高，驾驶行为越好）</span>
        </div>
        <div class="line"></div>
        <div id="chart_line"></div>
        <!-- <div v-show="scoreDist.scores.length == 0" class="null_data_chart">暂无数据</div> -->
    </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    name: 'ScoreDistribution',
    props: {
        scoreDist: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            myChart: '',
            newNumber: 0,
            chartsResponse: []
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize()
                }, 500)
            }
        })
    },
    watch: {
        scoreDist(value) {
            value && value.median && this.loadCharts(value)
        }
    },
    methods: {
        loadCharts(optionData) {
            var chartDom = document.getElementById('chart_line')
            var median = Math.round(optionData.median)
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' })
            var option = {
                xAxis: {
                    type: 'category',
                    name: '(分)',
                    nameTextStyle: {
                        color: '#B8D3F1'
                    },
                    boundaryGap: false,
                    // splitNumber:5,
                    axisLabel: {
                        interval: 2,
                        textStyle: {
                            color: '#B8D3F1' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: '2',
                            color: 'rgba(54, 153, 255, 0.5)'
                        }
                    },
                    data: optionData.scores.reverse()
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#B8D3F1' //坐标值得具体的颜色
                        }
                    },
                    name: '(辆)',
                    nameTextStyle: {
                        color: '#B8D3F1'
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: '2',
                            color: 'rgba(54, 153, 255, 0.5)'
                        }
                    },
                    splitLine: {
                        show: false
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b0} 分: {c0} 辆车'
                },
                grid: {
                    top: '34',
                    bottom: '20%',
                    left: '12%',
                    right: '40'
                },
                series: [
                    {
                        data: optionData.nums.reverse(),
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(67, 128, 240, 0.5)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(67, 128, 240,0)'
                                }
                            ])
                        },
                        markLine: {
                            symbol: 'circle',
                            label: {
                                normal: {
                                    show: true,
                                    color: '#B8D3F1',
                                    formatter: '评分中位数{c}'
                                }
                            },
                            symbolSize: 7,
                            lineStyle: {
                                type: 'dotted',
                                color: 'rgba(67, 128, 240, 0.5)',
                                width: 2
                            },
                            data: [
                                {
                                    xAxis: median + ''
                                }
                            ]
                        }
                    }
                ]
            }
            this.myChart.setOption(option)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 32%;
    background: url(../../public/images/msi/box_1.png) no-repeat;
    background-size: 100% 100%;
    padding: 0.2rem 0.2rem 0;
    position: relative;
}
.score_text {
    font-size: 0.18rem;
}
.score_text span {
    font-size: 0.14rem;
}
.line {
    width: 1.86rem;
    height: 1px;
    background: url(../../public/images/msi/line.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 0.14rem;
}
#chart_line {
    width: 98%;
    position: absolute;
    top: 0.53rem;
    left: 0;
    bottom: 0;
    z-index: 60;
}
</style>
