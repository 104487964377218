import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  version:'test', //manager  
	  // baseTitle:'宏瓴科技'  //宏瓴科技   
  },
  getters:{
 	  baseTitle(state){
 		  var version=state.version;
		  return 'Galileo—Mobility Platform' 
 	  },
 	  baseURL(state){
 		  var version=state.version;
 		  let href='//'+location.hostname+'/manager';
 		  if(location.hostname=='localhost'){
				//   href = '//ubiiovtest.sigreal.cn/manager'
				//   http://ubiallinone-test.sigreal.cn
				//   cargo-tpa.test.sigreal.cn
				// business.sigreal.cn
				//   ubiallinone-test.sigreal.cn
				href = '//ubiallinone-test.sigreal.cn/manager'
				//href = '//localhost:8080/manager'
 		  }
 		  // return 'http://172.200.189.37:19003/manager'  
 		  return href
 		//   return version=='huanqiu'?'https://evcard.sigreal.cn/manager':version=='newtuanche'?'https://cpicfleet.prd.sigreal.cn/manager':version=='baofeng'?'https://baofeng.sigreal.cn/manager':version=='chac'?'https://chac.sigreal.cn/manager':version=='tuanche'?'https://cpicfleet.sigreal.cn/manager':version=='bangbangxing'?'https://bbxubi.sigreal.cn/manager':version=='taibaosiqing'?'http://cpicsq.sigreal.cn/manager':version=='taibao'?'http://47.94.13.224:8737':version=='sun'?'https://sunshine.sigreal.cn/manager':version=='guoren'?'https://guoren.sigreal.cn/manager':'http://ubi.msi.sigreal.cn/manager'
 	  }
  }
})
